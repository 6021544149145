import { ConfigTypes } from "../utils/types/types"

export interface ProcessEnv {
  [key: string]: string | undefined
}
export let config: ConfigTypes = {
  baseURL: process.env.REACT_APP_BASEURL as string,
  baseURLFiles: process.env.REACT_APP_FILES_BASEURL as string,
  authURL: process.env.REACT_APP_AUTH_SERVICE_URL as string
}
